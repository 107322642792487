import { db } from 'dbs/clientDb';
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  setDoc,
  updateDoc
} from 'firebase/firestore';
import { orderBy } from 'lodash';
import { getBaseUrl } from 'utils/config';

import * as userService from './userService';
export async function createWorkshop(workshop) {
  try {
    const docRef = doc(db, `workshops/${workshop.uid}`);
    await setDoc(docRef, {
      title: workshop.title
    });
    const workshopRef = collection(db, `workshops/${workshop.uid}/workshop`);
    const newDoc = await addDoc(workshopRef, {
      title: workshop.title,
      summary: workshop.summary,
      talentUid: workshop.uid,
      status: workshop.status,
      mainImg: workshop?.mainImg,
      talent: doc(db, `users/${workshop.uid}`)
    });

    const userWorkShopsDoc = doc(db, `users/${workshop.uid}/workshops/${newDoc.id}`);
    await setDoc(userWorkShopsDoc, {
      workshop: doc(db, `workshops/${workshop.uid}/workshop/${newDoc.id}`)
    });

    return { status: 'ok', data: { id: newDoc.id } };
  } catch (error) {
    console.log('error @createWorkshop', error);
    return { status: 'ko', error: error?.message, data: null };
  }
}

async function createDisplayUrl({ displayUrl, talentUid, workshopId }) {
  try {
    const lookUpRef = doc(db, `displayUrl/${displayUrl}`);
    await setDoc(lookUpRef, {
      talentUid,
      workshopId
    });
  } catch (error) {
    console.log('error @create display url', error);
  }
}

export async function updateWorkshop(workshop) {
  try {
    const docRef = doc(db, `workshops/${workshop.talentUid}/workshop/${workshop.id}`);
    await updateDoc(docRef, {
      capacity: workshop.capacity,
      date: workshop.date,
      description: workshop.description,
      duration: workshop.duration,
      logo: workshop.logo,
      mainImg: workshop.mainImg,
      price: workshop.price,
      startTime: workshop.startTime,
      summary: workshop.summary,
      // talentDisplayName: workshop.talentDisplayName,
      talentSummary: workshop.talentSummary,
      timezone: workshop.timezone,
      title: workshop.title,
      category: workshop.category,
      displayUrl: workshop.displayUrl,
      displayWorkshop: workshop.displayWorkshop,
      id: workshop?.id,
      facebook: workshop?.facebook,
      twitter: workshop?.twitter,
      linkedin: workshop?.linkedin,
      instagram: workshop?.instagram
    });
    await createDisplayUrl({
      talentUid: workshop.talentUid,
      displayUrl: workshop.displayUrl,
      workshopId: workshop.id
    });
    return { status: 'ok' };
  } catch (error) {
    console.log('error @updateWorkshop', error);
    return { status: 'ko', error: error?.message, data: null };
  }
}

export async function getWorkshops(authUser) {
  const userWorkshopRefs = (await userService.getUserWorkshopRefs(authUser)) ?? [];
  const workshops = [];

  try {
    for (let ref of userWorkshopRefs) {
      const docRef = await getDoc(ref.workshop);
      const data = docRef.data();
      const talent = (await getDoc(data.talent))?.data();
      workshops.push({ ...docRef.data(), talent, id: ref.id });
    }

    const sorted = orderBy(workshops, ['date', 'asc']);
    return { status: 'ok', data: sorted };
  } catch (error) {
    console.log('error @getWorkshops', error);
    return { status: 'ko', error: error?.message, data: null };
  }
}

export async function getPublicWorkshops() {
  try {
    console.log('getPublicWorkshops getBaseUrl', getBaseUrl());
    const workshops = await fetch(`${getBaseUrl()}/api/public-workshops`, {
      headers: {
        Accept: 'application/json, text/plain, */*',
        'User-Agent': '*'
      }
    }).then((res) => res.json());

    return { status: 'ok', data: workshops?.data };
  } catch (error) {
    console.log('error @getPublicWorkshops service function', error);
    return { status: 'ko', error: error?.message, data: null };
  }
}

export async function getParticipantList({ talentUid, workshopId }) {
  try {
    const docRef = collection(db, `workshops/${talentUid}/workshop/${workshopId}/participants`);
    const docs = await getDocs(docRef);
    const data = [];
    docs.forEach((doc) => {
      data.push({ ...doc.data(), id: doc.id });
    });
    return data;
  } catch (error) {
    return { status: 'ko', error: error?.message, data: null };
  }
}

export async function getPublicWorkshop(displayUrl) {
  try {
    const workshop = await fetch(`/api/public-workshops/${displayUrl}`, {
      headers: {
        Accept: 'application/json, text/plain, */*',
        'User-Agent': '*'
      }
    }).then((res) => res.json());

    return { status: 'ok', data: workshop?.data };
  } catch (error) {
    return { status: 'ko', error: error?.message, data: null };
  }
}

export async function subscribeToWorkshop({ talentUid, workshopId, onChange }) {
  const docRef = doc(db, `workshops/${talentUid}/workshop/${workshopId}`);

  return onSnapshot(docRef, (docData) => {
    onChange({ ...docData.data(), id: docData.id });
  });
}

export async function getTalentFromWorkshopRef(workshop) {
  const talent = (await getDoc(workshop.talent))?.data();

  return { ...workshop, talent };
}
