export default function WorkshopModel(data) {
  return {
    capacity: data?.capacity,
    date: new Date(data?.date),
    description: data?.description,
    duration: data?.duration,
    logo: data?.logo,
    mainImg: data?.mainImg,
    price: data?.price,
    startTime: data?.startTime,
    summary: data?.summary,
    // talentDisplayName: data?.talentDisplayName,
    talentSummary: data?.talentSummary,
    timezone: data?.timezone,
    status: data?.status,
    talentUid: data?.talentUid,
    title: data?.title,
    id: data?.id,
    category: data?.category,
    displayUrl: data?.displayUrl,
    displayWorkshop: data?.displayWorkshop,
    talent: data?.talent,
    facebook: data?.facebook,
    twitter: data?.twitter,
    linkedin: data?.linkedin,
    instagram: data?.instagram
  };
}
