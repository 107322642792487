import { USER_ROLES } from 'constants/enums';
import WorkshopModel from 'models/WorkshopModel';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import * as workshopService from 'services/workshopService';
import { inDevEnvironment } from 'utils/config';
import { immer, log } from 'utils/immer';
import create from 'zustand';

import useAuth from './useAuth';
import useUser from './useUser';

export const WORKSHOP_STATUS = {
  Building: 'building',
  Published: 'published',
  Completed: 'completed'
};

export const useWorkshopStore = create(
  log(
    immer((set) => ({
      purge: () =>
        set((state) => {
          state.loading = [];
          state.workshop = null;
          state.participants = [];
        }),
      loading: true,
      setLoading: (status) => set(() => ({ loading: status })),
      workshop: null,
      setWorkshop: (workshop) => set(() => ({ workshop })),
      workshops: [],
      setWorkshops: (workshops) => set(() => ({ workshops: workshops.map(WorkshopModel) })),
      editWorkshop: ({ id, edited }) =>
        set((state) => {
          const index = state?.workshops?.findIndex((workshop) => workshop?.id === id);
          if (index === -1) return;
          state.workshops[index] = {
            ...state.workshops[index],
            ...edited
          };
        }),
      publicWorkshops: [],
      setPublicWorkshops: (publicWorkshops) =>
        set(() => ({ publicWorkshops: publicWorkshops ? publicWorkshops.map(WorkshopModel) : [] })),
      hits: [],
      setHits: (hits) => set(() => ({ hits })),
      participants: [],
      setParticipants: (participants) => set(() => ({ participants }))
    }))
  )
);

export default function useWorkshop() {
  const { authUser } = useAuth();
  const {
    workshops,
    setWorkshops,
    loading,
    setLoading,
    editWorkshop,
    publicWorkshops,
    setPublicWorkshops,
    setWorkshop,
    workshop,
    setHits,
    hits,
    participants,
    setParticipants,
    purge
  } = useWorkshopStore((state) => ({
    workshops: state.workshops,
    setWorkshops: state.setWorkshops,
    publicWorkshops: state.publicWorkshops,
    setPublicWorkshops: state.setPublicWorkshops,
    loading: state.loading,
    setLoading: state.setLoading,
    editWorkshop: state.editWorkshop,
    setWorkshop: state.setWorkshop,
    workshop: state.workshop,
    setHits: state.setHits,
    hits: state.hits,
    participants: state.participants,
    setParticipants: state.setParticipants,
    purge: state.purge
  }));
  const { query } = useRouter();
  const unsubscribeFromCurrentRef = useRef();
  const id = query?.id;

  const { user } = useUser();

  useEffect(() => {
    if (!user?.uid && user !== USER_ROLES.Initializing) {
      console.log('purge @useWorkshop');
      purge();
    }
  }, [user]);

  useEffect(async () => {
    if (!id) return;
    if (workshop?.id !== id) {
      unsubscribeFromCurrentRef?.current?.();
      const currentWorkshop = getWorkshopById(id);
      setWorkshop(currentWorkshop);
      if (!currentWorkshop) return;

      unsubscribeFromCurrentRef.current = await workshopService.subscribeToWorkshop({
        talentUid: currentWorkshop?.talentUid,
        workshopId: currentWorkshop?.id,
        onChange: async (changedWorkshop) => {
          const workshop = await workshopService.getTalentFromWorkshopRef(changedWorkshop);
          setWorkshop(workshop);
        }
      });
    }

    return () => unsubscribeFromCurrentRef?.current?.();
  }, [id, workshops]);
  useEffect(async () => {
    if (user?.uid) {
      const workshops = await getWorkshops();
      setWorkshops(workshops);
    }
    setLoading(false);
  }, [user]);

  async function createWorkshop(workshop) {
    return await workshopService.createWorkshop(workshop);
  }

  async function updateWorkshop(workshop) {
    return await workshopService.updateWorkshop(workshop);
  }

  async function getWorkshops() {
    if (query?.mediaRecorder) {
      return await getPublicWorkshops();
    } else {
      const workshops = await workshopService.getWorkshops(authUser);
      return workshops.status === 'ok' ? workshops.data : [];
    }
  }

  function getWorkshopById(id) {
    return workshops?.find?.((w) => w.id === id);
  }

  function getWorkshopDescription(id) {
    const workshop = getWorkshopById(id);
    if (!workshop) return null;
    const raw = workshop?.description;
    try {
      return JSON.parse(raw);
    } catch {
      // console.log('error @getWorkshopDescription', error);
      return [
        {
          type: 'paragraph',
          data: {
            text: raw ?? ''
          }
        }
      ];
    }
  }

  async function getParticipantList({ talentUid, workshopId }) {
    const res = await workshopService.getParticipantList({ talentUid, workshopId });
    if (res.status === 'ok') {
      setParticipants(res.data);
    }

    console.log('getParticipantList res', res);
    return res?.data ?? [];
  }

  async function getPublicWorkshops() {
    const workshops = await workshopService.getPublicWorkshops();
    if (workshops.status === 'ok') {
      setPublicWorkshops(workshops.data);
    }
    setLoading(false);
    return workshops?.data ?? [];
  }

  async function getPublicWorkshop(displayUrl) {
    const workshop = await workshopService.getPublicWorkshop(displayUrl);
    if (workshop.status === 'ok') {
      return workshop.data;
    }

    return workshop?.data ?? null;
  }

  return {
    createWorkshop,
    workshops,
    loading,
    editWorkshop,
    getWorkshopById,
    getWorkshopDescription,
    updateWorkshop,
    getPublicWorkshops,
    publicWorkshops,
    getPublicWorkshop,
    workshop,
    hits: inDevEnvironment ? publicWorkshops : hits, // WARNING: full text search only works on prod
    setHits,
    participants,
    getParticipantList
  };
}
